<template>
    <div class="accounts">
        <div class="accounts__wrap">
            <div class="accounts__header">
                <div class="header__top">
                    <h2 class="header__title">
                        Акты
                    </h2>
                    <button class="v-btn" @click="orderAct">Заказать акт сверку</button>
                </div>
                <div class="header__search">
                    <input v-model="search" type="text" class="header__search-input" placeholder="Поиск">
                </div>
            </div>
            <div class="accounts__body">
                <ul class="accounts__row row--header" v-if="true">

                    <li class="row__item row__item--filter row__number" @click="sortData('number')">
                        <div class="row__title">№</div>
                        <i class="row__filter-icon"
                           :class="{
                        'row__filter-icon--ascend': sort === 'number',
                        'row__filter-icon--descend': sort === '-number'
                       }"
                        />
                    </li>

                    <li class="row__item row__item--filter row__date" @click="sortData('created')">
                        <div class="row__title">Дата акта</div>
                        <i class="row__filter-icon"
                           :class="{
                        'row__filter-icon--ascend': sort === 'date_change',
                        'row__filter-icon--descend': sort === '-date_change'
                       }"
                        />
                    </li>

                    <li class="row__item row__item--filter row__sum" @click="sortData('amount')">
                        <div class="row__title">Сумма</div>
                        <i class="row__filter-icon"
                           :class="{
                        'row__filter-icon--ascend': sort === 'rating',
                        'row__filter-icon--descend': sort === '-rating'
                       }"
                        />
                    </li>

                    <li class="row__item row__numbers"  v-if="false">
                        <div class="row__title">Номера связанных счётов</div>
                    </li>

                    <li class="row__item row__download">
                        <div class="row__title">Скачать акт</div>
                    </li>
                </ul>
                <div class="accounts__list">
                    <row v-for="item in data.results" :key="item.id" :act="item"></row>
                </div>
            </div>
        </div>
        <div class="accounts__pagination" v-if="data.count > 1">
            <paginate
                v-show="data.count > 1 || true"
                :click-handler="loadList"
                :container-class="'pagination__list'"
                :page-class="'pagination__item'"
                :active-class="'pagination__item--active'"
                :page-count="data.count"
                :hide-prev-next=true
                :next-text="''"
                :prev-text="''">
            </paginate>
            <div class="pagination__display">
                <span class="pagination__title">Показывать</span>
                <v-select2 class="pagination__select"
                           v-model="limit"
                           :reduce="label => label.value" label="label"
                           :clearable="false"
                           :searchable="false"
                           :options="paginationLimit">
                </v-select2>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import Paginate from "vuejs-paginate";

    export default {
        name: "Act",
        components: {
            Row : () => import('./Row'),
            Paginate
        },
        data() {
            return {
                search: '',
                sort: '',
                limit: 5,
                paginationLimit: [
                    {
                        value: 5,
                        label: 5
                    },
                    {
                        value: 10,
                        label: 10
                    },
                    {
                        value: 25,
                        label: 25
                    },
                    {
                        value: 50,
                        label: 50
                    }
                ],
                data: {
                    count: 0,
                    next: null,
                    previous: null,
                    results: []
                },
            }
        },
        watch: {
            search: {
                handler: function (val, oldVal) {
                    this.loadList()
                },
                deep: true
            }
        },
        created() {
            this.loadList()
        },
        methods: {
            orderAct() {
                const ActOrder = () => import(`../modals/ActOrder`);
                this.$modal.show(ActOrder, {

                }, {
                    name: 'ActOrder',
                    adaptive: true,
                    maxWidth: 740,
                    width: '100%',
                    height: 'auto'
                })
            },
            sortData(key) {
                if (this.sort && this.sort.indexOf(key) === -1) {
                    this.sort = key;
                } else if (!this.sort) {
                    this.sort = key;
                } else if (key === this.sort) {
                    this.sort = `-${key}`;
                } else {
                    this.sort = null;
                }
                this.loadList();
            },
            async loadList(pageNum=null) {
                let data, response;
                let offset = 0;
                if (pageNum) {
                    offset = (pageNum -1) * this.limit
                }
                const params = {
                    limit: this.limit,
                    offset: offset,
                    sort: this.sort,
                    search: this.search
                };
                try {
                    const request = session.get('/api/v1/act-payer/', {params});
                    response = await request;
                    this.data = response.data;
                    this.data.count = Math.ceil(this.data.count / this.limit);
                } catch (err) {
                    this.errorHandler(err);
                }
            },
            errorHandler(err) {
                console.log(err);
                this.$swal({
                    title: 'Ошибка!',
                    text: 'При обработке запроса произошла ошибка на сервере',
                    icon: 'error',
                    showConfirmButton: false,
                    timer: 1400,
                });
                this.isLoading = false;
            },
        },
    }
</script>

<style lang="scss">
    @import "../billing";
</style>
